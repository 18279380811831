import TextField from "@mui/material/TextField";
import React from "react";

export default function Field(props) {
    function update(event) {
       props.state[1](event.target.value);
    }

    return(
        <TextField
            fullWidth
            autoComplete={'off'}
            value={props.state[0]}
            onChange={update}
            id={props.name}
            label={props.name}
            variant="standard" />
    )
}