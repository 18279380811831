import Popover from '@mui/material/Popover';
import React from "react";
import AddForm from "./AddForm";

export default function AddEventDialog(props) {
    const handleClose = () => {
        props.setAnchorAdd(null);
    };

    const open = Boolean(props.anchorAdd);

    return (
        <div>
            <Popover
                open={open}
                anchorEl={props.anchorAdd}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <AddForm {... props}/>
            </Popover>
        </div>
    );
}