import React, {useState} from "react";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import AddEventDialog from "./AddEventDialog";
import DownloadFile from "../Utils/DownloadFile";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadEventDialog from "./UploadEventDialog";

export default function SimpleBottomNavigation(props) {
    const [anchorAdd, setAnchorAdd] = useState(null);
    const [anchorUpload, setAnchorUpload] = useState(null);

    const handleAdd = (event) => {
        setAnchorAdd(event.currentTarget);
    };

    const handleUpload = (event) => {
        setAnchorUpload(event.currentTarget);
    };

    function handleClear() {
        props.timelineActions.removeAll();
    }

    function handleSave() {
        const timelineJSON = JSON.stringify(props.timeline);
        const fileName = "timeline";
        DownloadFile(fileName + ".json", "application/json", timelineJSON);
    }

    return (
        <Box sx={{ pb: 7 }}>
            <CssBaseline />
            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
                <BottomNavigation showLabels>
                    <BottomNavigationAction label="Add" icon={<AddIcon />} onClick={handleAdd}/>
                    <AddEventDialog
                        anchorAdd={anchorAdd}
                        setAnchorAdd={setAnchorAdd}
                        timelineActions={props.timelineActions}
                    />
                    <BottomNavigationAction label="Clear" icon={<ClearIcon/>} onClick={handleClear}/>
                    <BottomNavigationAction label="Save" icon={<SaveAltIcon/>} onClick={handleSave}/>
                    <BottomNavigationAction label="Upload" icon={<UploadFileIcon/>} onClick={handleUpload}/>
                    <UploadEventDialog
                        anchorUpload={anchorUpload}
                        setAnchorUpload={setAnchorUpload}
                        timelineActions={props.timelineActions}
                    />
                </BottomNavigation>
            </Paper>
        </Box>
    );
}