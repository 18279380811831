import Popover from '@mui/material/Popover';
import React from "react";
import UploadForm from "./UploadForm";

export default function UploadEventDialog(props) {
    const handleClose = () => {
        props.setAnchorUpload(null);
    };

    const open = Boolean(props.anchorUpload);

    return (
        <div>
            <Popover
                open={open}
                anchorEl={props.anchorUpload}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <UploadForm {...props}/>
            </Popover>
        </div>
    );
}