import TimelineItem from "@mui/lab/TimelineItem";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import * as React from "react";

const scheme = [
    "#EFC7C2",
    "#FFE5D4",
    "#BFD3C1",
    "#68A691",
    "#61707D"
]

export default function TimelineBody(props) {
    const children = props.timeline.map((item, index) =>
        <TimelineItem key={index} sx={{ minHeight: props.timelineActions.distanceToNext(index) + 'px'}}>
            <TimelineOppositeContent color="textSecondary">
                { item["date"] }
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot/>
                <TimelineConnector sx={{ backgroundColor:colorPicker(index) }}/>
            </TimelineSeparator>
            <TimelineContent>
                { item["event"] }
            </TimelineContent>
        </TimelineItem>
    )

    return (children)
}

function colorPicker(index) {
    return scheme[index % scheme.length];
}