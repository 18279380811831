import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

export default function AddForm(props) {
    function handleFileInput(event) {
        const file = event.target.files[0];

        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
            props.timelineActions.readJSON(e.target.result);
        });
        reader.readAsText(file);

        // close popup
        props.setAnchorUpload(null);
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch', display: 'block' },
                p: 2
            }}
        >
            <Button
                variant="contained"
                component="label"
            >
                Upload File
                <input
                    type="file"
                    hidden
                    onChange={handleFileInput}
                />
            </Button>
        </Box>
    )
}