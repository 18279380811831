import './App.css';
import React from "react";
import MainTimeline from "./Timeline/MainTimeline";
import SimpleBottomNavigation from "./Nav/SimpleBottomNavigation";
import {useTimeline} from "./Hooks/useTimeline";


function App() {
    const {timeline, timelineActions} = useTimeline();

    return (
        <>
            <MainTimeline timeline={timeline} timelineActions={timelineActions}/>

            <SimpleBottomNavigation timeline={timeline} timelineActions={timelineActions}/>
        </>
    );
}

export default App;
