import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineBody from "./TimelineBody";

export default function MainTimeline(props) {
    return (
        <Timeline>
            <TimelineBody { ...props }/>
        </Timeline>
    )
}
