import {useState} from "react";

export function useTimeline() {
    const [timeline, setTimeline] = useState([])

    const context =
        {
            timeline,
            setTimeline
        };

    const timelineActions = {
        distanceToNext: (index) => distanceToNext(index, context),
        append: (event) => append(event, context),
        readJSON: (json) => readJSON(json, context),
        removeAll: () => removeAll(context)
    };

    return {timeline, timelineActions};
}

function distanceToNext(index, context) {
    const {timeline} = context;

    if (index === timeline.length - 1)
        return 70;

    const date = parseInt(timeline[index]['date'], 10);
    const nextDate = parseInt(timeline[index + 1]['date'], 10);

    const diff = nextDate - date;

    if (diff <= 1)
        return 20;
    if (diff < 5)
        return 40;
    if (diff < 10)
        return 80;
    if (diff < 20)
        return 150;
    if (diff < 50)
        return 300;
    else
        return 600;
}

function readJSON(string, context) {
    const {setTimeline} = context;

    setTimeline(JSON.parse(string));
}

function append(event, context) {
    const {timeline, setTimeline} = context;

    const newTimeline = [...timeline];

    newTimeline.push(event);

    newTimeline.sort((a, b) => {
        if (a['date'] < b['date'])
            return -1;
        if (a['date'] > b['date'])
            return 1;
        return 0;
    })

    setTimeline(newTimeline);
}

function removeAll(context) {
    const {setTimeline} = context;
    setTimeline([]);
}