import React, {useState} from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Field from "./Field";

export default function AddForm(props) {
    const [date, setDate] = useState("");
    const [description, setDescription] = useState("");

    function handleClick(event) {
        event.preventDefault();
        props.timelineActions.append({
            "date": date,
            "event": description
        })

        setDate("");
        setDescription("");
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch', display: 'block' },
                p: 2
            }}
        >
            <Field state={[date, setDate]} name={"Date"} numeric={true}/>
            <Field state={[description, setDescription]} name={"Description"}/>
            <Button
                type="submit" variant="contained" onClick={handleClick}>ADD</Button>
        </Box>
    )
}